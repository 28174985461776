.main-nav {
  width: 90;
  height: 90;
  display: grid;
  grid-template-columns: 10rem 1fr;
  width: 90;
  height: 90;
}
.main-container-scroll {
  overflow-y: auto; /* Allows scrolling */
  scrollbar-width: none; /* For Firefox */
}

.main-container-scroll::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Edge */
}

.menu {
  margin-top: 30;
  display: "flex";

  justify-content: space-between;
  margin-left: 50;
}
.type-cursor {
  cursor: pointer;
}
.logo-img {
  height: 40px;
  width: 140px;
  object-fit: contain;
  /* background-color: white; */
}
.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}
@media only screen and (max-width: 600px) {
  .logo-img {
    height: 50px;
    width: 140px;
    object-fit: contain;
    /* background-color: white; */
  }
  .type-cursor {
    cursor: auto;
  }
}
.tabs-container {
  min-height: 36px;
  background-color: #e3f2fd;
  border-radius: 10px;
  padding: 4px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.tab {
  flex: 1;
  font-size: 13px;
  font-weight: 500;
  text-transform: none;
  padding: 8px 14px;
  min-height: 36px;
  min-width: auto;
  border-radius: 8px;
  color: #1976d2;
  text-align: center;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
}

.tab:hover {
  background-color: #bbdefb;
}
.custom-progress-bar {
 background: linear-gradient(180deg, #1770e0, #4da6ff) !important;
  height: 3px !important; /* Reduce height of the progress bar */
}
.custom-toast {
 font-size: 12px !important; /* Smaller font */
  max-width: 400px !important; /* Restrict width */
  min-height: 40px !important; /* Reduce height */
  padding: 8px 15px 8px 12px !important; /* Right padding to prevent overlap */
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
  white-space: normal !important; /* Ensures text breaks */
  display: flex !important;
  flex-direction: column !important; /* Prevents text from stretching inline */
  align-items: flex-start !important;
  position: relative !important;
}

.tab.active {
  color: white;
  background-color: #1565c0;
  animation: fadeIn 0.3s ease-in-out;
}

.tab.active:hover {
  background-color: #0d47a1;
}

/* Animation for smooth left and right transitions */
@keyframes slideLeft {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideRight {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* .slide-left {
  animation: slideLeft 0.4s ease-in-out;
}

.slide-right {
  animation: slideRight 0.4s ease-in-out;
} */
