.action-buttons-container {
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
  gap: 16px;
}

.dashboard-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 12px;
  width: calc(100% - 10px);
  padding: 0 10px;
}

.dashboard-card {
  height: 50px;
  padding: 6px 12px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  text-align: center;
  transition:
    transform 0.2s,
    box-shadow 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  cursor: pointer;
}
.dashboard-card.active {
  background: linear-gradient(90deg, #1770e0, #4da6ff, #002b80);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  transform: scale(0.98);
  color: #fff;
}
.dashboard-card-skelton {
  height: 50px;
  padding: 6px 12px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  text-align: center;
  transition:
    transform 0.2s,
    box-shadow 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Center text horizontally */
  background-color: #f9f9f9; /* Light background for better visibility */
}

.dashboard-card:hover {
  transform: scale(1.05);
  box-shadow: 0px 2px 10px rgba(23, 112, 224, 0.6);
  color: white;
}
/* Skeleton Effect - Softer and Slower */
.skeleton {
  background: linear-gradient(90deg, #f2f2f2 25%, #ffffff 50%, #f2f2f2 75%);
  background-size: 200% 100%;
  animation: shimmer 2.5s infinite ease-in-out; /* Slowed down animation */
}

/* Skeleton title and total placeholders */
.skeleton-title,
.skeleton-total {
  width: 80%;
  height: 12px;
  border-radius: 4px;
  background: #f5f5f5; /* Light gray */
  margin-bottom: 5px;
}

.skeleton-total {
  width: 50%;
  height: 14px;
}

/* Shimmer effect animation */
@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.card-title-tag {
  font-size: 0.9rem; /* Slightly smaller for balance */
  margin: 0; /* Remove unnecessary space */
  line-height: 1.2; /* Improve text spacing */
  background: linear-gradient(45deg, #000, #000, #000);

  -webkit-background-clip: text;
  color: transparent;
  font-family: "Poppins", sans-serif;
}
.card-title-tag.active {
  color: #fff;
}

.card-total {
  font-size: 1.3rem; /* Adjusted for better alignment */
  margin: 0;
  line-height: 1.2;
  /* background: linear-gradient(45deg, #ffc75f, #ff9671); */
  background: linear-gradient(90deg, #1770e0, #4da6ff, #002b80);
  -webkit-background-clip: text;
  color: transparent;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}
.card-total.active {
  color: #fff;
}

.dashboard-table-container {
  width: 100%;
  overflow-x: auto;
}

.dashboard-text-alignment {
  text-align: center;
}
.dashboard-table {
  /* width: 100%; */
  border-collapse: collapse;
  border: 0px solid #ddd;
}

.dashboard-table th {
  background: linear-gradient(180deg, #1770e0, #4da6ff);
  color: white;
  text-align: left;
  box-shadow: 0px 4px 8px rgba(23, 112, 224, 0.3); /* Smooth shadow */
}
.dashboard-table th,
.dashboard-table td {
  border: 0px solid #fff;
  /* padding: 10px; */
  text-align: left;
  font-size: 12px;
  height: 25px;
  border-bottom: 1px solid #fff;
}

.dashboard-table th {
  /* background-color: #f4f4f4; */
  font-weight: bold;
  font-size: 12px;
  height: 25px;
}

.dashboard-table tbody tr:nth-child(even) {
  /* background-color: #f9f9f9; */
}

.dashboard-table tbody tr:hover {
  background-color: #e6f3ff;
}
.dashboard-table tr:nth-child(even) {
  background-color: #f8fcff;
  color: #000;
}
.dashboard-table tr:nth-child(odd) {
  background-color: #fff;
  color: #000;
}

.pages-box {
  /* display: flex; */
  /* flex-direction: row; */
  /* justify-content: center; */
  /* align-items: center; */
  /* height: 40vh; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.view-pages-box {
  /* display: flex; */
  /* flex-direction: row; */
  /* justify-content: center; */
  /* align-items: center; */
  /* height: 80vh; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-card {
  background-image: url("../assets/images/card.png");
  background-size: cover;
  border-radius: 5px;
  height: 40vh;
  width: 50%;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 18px;
  margin-left: 18px;
  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.2);
}
.page-card:hover {
  box-shadow: 0 8px 10px 0 black;
  box-shadow: 0 8px 16px 0 gray;
  transition: all 0.3s;
  transform: scale(1.05);
}
.icon-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fffde3;
  height: 60px;
  width: 60px;
  border-radius: 30px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
}
.card-text {
  /* font-style: italic; */
  font-weight: bold;
  color: #4c4c4c;
  margin-top: 20;
}
.dashboard-header {
  background: linear-gradient(#0d7bd3, transparent);
  background-color: #284b8c;
  height: 14vh;
  display: flex;
  margin-bottom: 10px;
  justify-content: flex-start;
  align-items: center;
}
.logo-box {
  margin-left: 18px;
  border-radius: 10px;
  /* height: 70px; */
  /* margin-top: 20px; */

  /* width: 80px; */
  /* background-color: #fff; */
  /* display: flex;
  justify-content: center; */
}
.dashboard-data-table-margin {
  /* margin-top: -40px; */
}

.dashboard-main-container {
  /* background-color: #eceeef; */
  background-color: #fff;
  min-height: 90vh;
}
.dashboard-bg {
  background-image: url("../assets/images/bg1.png");
}

.dashboard-main-container-header {
  font-size: 27px;
  font-weight: bold;
  color: #fff;
  margin-left: 10px;
}
.dashboard-inorbit-logo {
  height: 100px;
  width: 200px;
  object-fit: contain;
  /* margin-top: 10px; */
}

@media only screen and (max-width: 600px) {
  .pages-box {
    flex-direction: column;
  }
  .page-card {
    background-image: url("../assets/images/card.png");
    background-size: cover;
    border-radius: 5px;
    height: 40vh;
    width: 95%;
    margin-bottom: 10px;
    margin-top: 10px;
    /* padding-left: 10px; */
    /* padding-right: 10px; */
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 18px;
    margin-left: 18px;
    box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.2);
  }

  .dashboard-main-container-header {
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    margin-left: 5px;
  }
  .dashboard-inorbit-logo {
    height: 100px;
    width: 180px;
    object-fit: contain;
    border-radius: 10px;
    margin-left: 10px;
    /* margin-top: -15px; */
  }
  .logo-box {
    margin-left: 10px;
    border-radius: 10px;
    /* height: 70px; */
    /* width: 180px; */
    /* background-color: #fff; */
    /* display: flex;
    justify-content: center; */
  }
  .action-buttons-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .action-buttons-grid-item {
    text-align: start;
    /* width: 100%; */
  }
  .dashboard-data-table-margin {
    margin-top: 0px;
  }
}
