/* body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
  color: #333; */
right-container
/* } */


.performance-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Allows wrapping for smaller screens */
  gap: 16px;
}

/* Left section for the title */
.performance-container .left-section {
  flex: 1; /* Takes available space */
  text-align: start;
}

/* Right section for the dropdowns */
.performance-container .right-section {
  display: flex;
  gap: 16px;
  flex: 1; /* Adjust width */
  justify-content: flex-end;
  flex-wrap: wrap; /* Wrap dropdowns for smaller screens */
}

.performance-container .right-section .dropdown-box {
  width: 200px; /* Fixed width for dropdowns */
}

/* Title and Subtitle styling */
.performance-container .title {
  font-size: 22px;
  color: #000;
  font-family: "Poppins";
}

.performance-container .subtitle {
  font-size: 15px;
  color: gray;
  font-family: "Poppins";
}

/* Dropdown label styling */
.performance-container .dropdown-label {
  font-size: 15px;
  color: gray;
  font-weight: bold;
  font-family: "Poppins";
  text-align: start;
}

.email-container {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}
.campaign-report-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  /* height: 70vh; */
  background-image: url("../../assets/images/bg1.png");
  /* background-image: url("../assets/images/bg1.png"); */
}
.import-recipient-wrapper {
  width: 80%;
}

.campaign-report-container {
  min-width: 70%;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.campaign-detail {
  display: grid;
  grid-template-columns: 200px 1fr; /* Adjust heading width */
  align-items: center;
  gap: 70px;
  margin-bottom: 15px;
}

.campaign-detail p {
  font-size: 1rem;
  margin: 0;
  color: #555;
}

.campaign-detail strong {
  color: #333;
  font-weight: bold;
}

.campaign-detail a {
  color: #007bff;
  text-decoration: none;
}

.campaign-detail a:hover {
  text-decoration: underline;
}
.campaign-report-heading {
  font-size: 2rem; /* Large font size for emphasis */
  font-weight: 600; /* Semi-bold for clarity */
  color: #333; /* Dark color for readability */
  margin-bottom: 20px; /* Space below the heading */
  text-align: center; /* Center align the heading */
  text-transform: capitalize; /* Ensure proper capitalization */
  border-bottom: 2px solid #007bff; /* Optional underline effect */
  padding-bottom: 10px; /* Space between text and underline */
  font-family: "poppins"; /* Ensure clean font */
}

.prev-email-template {
  border: 2px solid rgb(91, 91, 240);
  border-radius: 50px;
  /* color:  rgb(91, 91, 240); */
  color: rgb(91, 91, 240);
  background-color: #fff;
  width: 160px;
  display: flex;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
}
.prev-email-template:hover {
  background-color: rgb(235, 235, 255); /* Change background to blue */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a subtle shadow effect */
  transform: translateY(-2px); /* Slightly elevate the button */
}

.campaign-analysis-grid-container {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(200px, 1fr)
  ); /* Responsive grid */
  gap: 15px; /* Space between cards */
  padding: 20px;
}
.campaign-analysis-card {
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  /* padding: 15px; */
  text-align: center;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  /* background: linear-gradient(135deg, #ff7e5f, #feb47b); */
  transition:
    transform 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
  /* height: 100px; */
  width: 100%;
  margin: 0;
  cursor: pointer;
}

.campaign-analysis-card:hover {
  transform: scale(1.05);
  box-shadow: 0px 6px 15px rgb(255, 126, 95);
}

.campaign-analysis-time-text {
  font-size: 24px;
  font-weight: bold;
  color: gray;
  margin: 0;
  margin-bottom: 5px;
}

.campaign-analysis-text {
  font-size: 13px;
  font-weight: 500;
  margin: 0;
  color: gray;
}

/* .campaign-analysis-card {
  width: 280px;
  background: #1770e0;
  border-radius: 15px;
  box-shadow:
    rgb(0, 0, 0, 0.1) 5px 10px 50px,
    rgb(0, 0, 0, 0.1) -5px 0px 250px;
  color: white;
  background: linear-gradient(to right, #1770e0, #5696e3);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.campaign-analysis-text {
  font-size: 15px;
  margin-left: 5px;
}
.campaign-analysis-time-text {
  font-size: 50px;
  margin-top: 0px;
  margin-left: 15px;
  font-weight: 600;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
} */

.email-label {
  background-color: transparent;
  border: 2px solid rgb(91, 91, 240);
  display: flex;
  align-items: center;
  border-radius: 50px;
  width: 160px;
  cursor: pointer;
  transition: all 0.4s ease;
  padding: 5px;
  position: relative;
}

.email-label:hover {
  background-color: rgb(235, 235, 255); /* Original color on hover */
  color: #fff; /* White text */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

/* .email-label::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  width: 8px;
  height: 8px;
  transition: all 0.4s ease;
  border-radius: 100%;
  margin: auto;
  opacity: 0;
  visibility: hidden;
}

.email-label .email-input {
  display: none;
} */

.email-label .email-title {
  font-size: 16px;
  color: rgb(91, 91, 240);
  transition: all 0.4s ease;
  position: absolute;
  right: 15px;
  bottom: 0;
  text-align: center;
}

/* .email-label .email-title:last-child {
  opacity: 0;
  visibility: hidden;
} */

.email-label .email-circle {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  background-color: rgb(91, 91, 240);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s ease;
  position: relative;
  box-shadow: 0 0 0 0 rgb(255, 255, 255);
  overflow: hidden;
}

.email-label .email-circle .email-icon {
  color: #fff;
  width: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.4s ease;
}

/* .email-label .email-circle .email-square {
  aspect-ratio: 1;
  width: 15px;
  border-radius: 2px;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.4s ease;
} */

/* .email-label .email-circle::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background-color: #3333a8;
  width: 100%;
  height: 0;
  transition: all 0.4s ease;
} */
/* 
.email-label:has(.email-input:checked) {
  width: 57px;
  animation: installed 0.4s ease 3.5s forwards;
}

.email-label:has(.email-input:checked)::before {
  animation: rotate 3s ease-in-out 0.4s forwards;
}

.email-label .email-input:checked + .email-circle {
  animation:
    pulse 1s forwards,
    circleDelete 0.2s ease 3.5s forwards;
  rotate: 180deg;
}

.email-label .email-input:checked + .email-circle::before {
  animation: installing 3s ease-in-out forwards;
}

.email-label .email-input:checked + .email-circle .email-icon {
  opacity: 0;
  visibility: hidden;
}

.email-label .email-input:checked ~ .email-circle .email-square {
  opacity: 1;
  visibility: visible;
}

.email-label .email-input:checked ~ .email-title {
  opacity: 0;
  visibility: hidden;
}

.email-label .email-input:checked ~ .email-title:last-child {
  animation: showInstalledMessage 0.4s ease 3.5s forwards;
} */
/* 
@keyframes pulse {
  0% {
    scale: 0.95;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }
  70% {
    scale: 1;
    box-shadow: 0 0 0 16px rgba(255, 255, 255, 0);
  }
  100% {
    scale: 0.95;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes installing {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(-90deg) translate(27px) rotate(0);
    opacity: 1;
    visibility: visible;
  }
  99% {
    transform: rotate(270deg) translate(27px) rotate(270deg);
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes installed {
  100% {
    width: 150px;
    border-color: rgb(35, 174, 35);
  }
}

@keyframes circleDelete {
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes showInstalledMessage {
  100% {
    opacity: 1;
    visibility: visible;
    right: 56px;
  }
} */

.email-template {
  --bg: #fff;
  --hover-bg: #fff;
  --hover-text: #fff;
  height: 300px;
  width: 100%; /* Ensure items span their column width */
  max-height: 300px;
  background-color: var(--bg);
  text-align: center;
  border-radius: 5px;
  transition:
    0.3s cubic-bezier(0.6, 0.4, 0, 1),
    transform 0.15s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}

.email-template img {
  width: 100%; /* Image spans the full width of the div */
  height: 100%; /* Image fills the full height of the div */
  object-fit: cover; /* Crops the image while preserving aspect ratio */
  object-position: top; /* Ensures the upper part of the image is visible */
}

.campaign-details-container {
  display: flex;
  justify-content: center;
}
.container {
  /* max-width: 800px; */
  width: 70%;
  /* margin: 2rem auto; */
  padding: 1rem;
  background: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.fullscreen-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* Stack the content vertically */
  z-index: 9999;
}

.fullscreen-loader p {
  margin-top: 20px; /* Space between animation and text */
  font-size: 24px; /* Adjust the text size */
  font-family: "Arial", sans-serif; /* Choose a clean, modern font */
  font-weight: bold;
  color: #fff; /* Text color */
  text-align: center; /* Center the text */
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4); /* Add a shadow for better visibility */
}

.right-container {
  width: 30%;
}

.right-sub-container {
  display: flex;
  position: sticky;
  top: 40px;
  right: 0;
  align-items: center;
  max-height: 100vh;
  background-color: #fff;
}
h1 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1rem;
  padding: 0 1rem;
  font-family: "poppins";
}
.edit-name-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1rem;
  padding: 0 1rem;
  font-family: "poppins";
}

.edit-name {
  font-size: 1rem;
  font-weight: normal;
  color: #1770e0;
  margin-top: -0.5rem;
  margin-bottom: 1.5rem;
  padding: 0 1rem;
  text-decoration: underline;
  cursor: pointer;
  font-family: "poppins";
}

.email-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  padding: 1rem;
}

.email-item:last-child {
  border-bottom: none;
}

.email-item-label {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.email-item-label i {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #f4f4f4;
  color: #333;
  font-size: 1.2rem;
  font-weight: bold;
}

.email-item-label span {
  font-size: 1.3rem;
  font-weight: 500;
  color: #333;
}

.email-item-description {
  font-size: 1rem;
  color: #666;

  /* margin-left: 2rem; */
}

.email-item button {
  font-size: 0.9rem;
  width: 150px;
  font-weight: 500;
  color: #1770e0;
  background: none;
  border: 1px solid #1770e0;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition:
    background-color 0.2s,
    color 0.2s;
}

.email-item button:hover {
  background-color: #1770e0;
  color: #fff;
}

.status-icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4caf50; /* Green for completed */
  color: #fff;
  font-size: 0.8rem;
  font-weight: bold;
}

.status-icon.error {
  background-color: #f44336; /* Red for incomplete */
  color: #fff;
}

.email-item-resolve {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background-color: #fff6f6;
  border: 1px solid #f4c6c6;
  border-radius: 8px;
}

.email-item-resolve span {
  font-size: 1rem;
  color: #333;
}

.email-item-resolve button {
  font-size: 0.9rem;
  color: #f44336;
  border: 1px solid #f44336;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  background: none;
  cursor: pointer;
  transition:
    background-color 0.2s,
    color 0.2s;
}

.email-item-resolve button:hover {
  background-color: #f44336;
  color: #fff;
}

.text-means-web {
  font-family: "Means Web", Georgia, Times, "Times New Roman", serif;
}

.recipient-description {
  font-size: 20px;
  margin: 0;
}
.recipient-title {
  font-size: 30px;
  font-weight: bold;
  margin: 0;
}

/* Card.css */
.effective-subject-card-container {
  /* max-width: 400px; */
  background-color: #f6f6f6;

  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin: 10px auto;
  font-family: Arial, sans-serif;
  color: #333;
  transition:
    transform 0.3s ease-in-out,
    box-shadow 0.3s ease-in-out,
    border-color 0.3s ease-in-out;
  /* transition: border 0.3s ease, box-shadow 0.3s ease; */
}
.effective-subject-card-container:hover {
  transform: translateY(-5px);
  /* border: 2px solid #000; */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.card-title {
  /* font-size: 1.5rem;
  margin-bottom: 10px;
  font-family: poppin;
  color: #1770E0;  */
  font-size: 1.5rem;
  margin-bottom: 10px;
  background: linear-gradient(
    45deg,
    #4caf50,
    #e458a7
  ); /* Light pastel gradient */
  -webkit-background-clip: text; /* Ensures the gradient is applied to the text */
  color: transparent; /* Makes the text color transparent to show the gradient */
  font-weight: bold;
  font-family: "poppins";
}

.card-content {
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
}

.card-content li {
  margin-bottom: 10px;
  line-height: 1.5;
}

.card-content strong {
  color: #333;
}

.card-footer {
  font-size: 0.9rem;
  margin-top: 15px;
  color: #555;
  text-align: center;
  font-style: italic;
}

.import-card-main-container {
  display: flex;
  justify-content: space-evenly;
}

.import-card-container {
  width: 300px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
  cursor: pointer;
  margin: 20px;
}

.import-card-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  background-color: #a8d5ba; /* Light Green */
}

.image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  /* height: 150px; */
}

.card-image {
  width: 200px; /* Adjust the image size */
  height: 200px;
  object-fit: contain;
}

.card-content {
  padding: 15px;
  text-align: center;
}

.card-content h3 {
  font-size: 20px;
  color: #333;
  margin: 10px 0;
}

.card-content p {
  font-size: 14px;
  color: #666;
  line-height: 1.5;
}

.import-card-container:hover .card-content h3 {
  color: #ffffff;
}

.import-card-container:hover .card-content p {
  color: #ffffff;
}
.import-card-container:hover .card-content strong {
  color: #ffffff;
}

.import-csv-main-container {
  display: flex;
  justify-content: space-around;
  padding: 15px;
}

.csv-template-download {
  width: 300px;
  height: 300px;
  border: 1px solid #4caf50;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition:
    border-color 0.3s,
    background-color 0.3s;
  background-color: #e8f5e9;
  color: gray;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
}
.csv-template-download:hover {
  border-color: #2e7d32; /* Darker green border on hover */
  background-color: #c8e6c9; /* Light greenish background */
}
.import-csv-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-label {
  cursor: pointer;
}

.upload-box {
  width: 500px;
  height: 300px;
  border: 3px dashed #4caf50; /* Light green border */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition:
    border-color 0.3s,
    background-color 0.3s;
  background-color: #e8f5e9;
}

.upload-box:hover {
  border-color: #2e7d32; /* Darker green border on hover */
  background-color: #c8e6c9; /* Light greenish background */
}

.upload-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.upload-text {
  font-family: Arial, sans-serif;
  font-size: 14px;
  text-align: center;
  color: #333;
}

.back-button {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between icon and text */
  padding: 1px 10px;
  font-size: 16px;
  font-family: Arial, sans-serif;
  color: white;
  background-color: #333; /* Dark background */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.back-button:hover {
  background-color: #000; /* Light green background on hover */
  color: #fff; /* Keep text white */
  transform: translateY(-3px); /* Button rises on hover */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15); /* Add deeper shadow on hover */
}

.back-button:active {
  transform: scale(0.95); /* Shrinks slightly when clicked */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Reduces shadow depth */
}

.back-icon {
  width: 20px;
  height: 20px;
  filter: invert(100%); /* Makes the image white */
  transition: filter 0.3s ease;
}

.back-button:hover .back-icon {
  filter: invert(70%); /* Grey effect on hover */
}

.back-text {
  color: white;
  transition: color 0.3s ease;
}

.back-button:hover .back-text {
  color: white;
}

.filled-button {
  background-color: #4caf50; /* Primary green color */
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  font-family: Arial, sans-serif;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.filled-button:hover {
  background-color: #388e3c; /* Darker green on hover */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Adds a hover shadow */
}

.bordered-button {
  background-color: transparent;
  color: #4caf50; /* Match the filled button's green */
  padding: 10px 20px;
  font-size: 16px;
  font-family: Arial, sans-serif;
  border: 2px solid #4caf50; /* Green border */
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.bordered-button:hover {
  background-color: #e8f5e9; /* Light green background on hover */
  color: #388e3c; /* Darker green text on hover */
  border-color: #388e3c; /* Darker green border on hover */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Adds a hover shadow */
}
.filled-button-blue {
  background-color: #1770e0; /* Primary green color */
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  font-family: Arial, sans-serif;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.filled-button-blue:hover {
  background-color: #1770e0; /* Darker green on hover */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Adds a hover shadow */
}
.bordered-button-blue {
  background-color: transparent;
  color: #1770e0; /* Match the filled button's green */
  padding: 10px 20px;
  font-size: 16px;
  font-family: Arial, sans-serif;
  border: 1px solid #1770e0; /* Green border */
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.bordered-button-blue:hover {
  background-color: #ebeef1; /* Light green background on hover */
  color: #1770e0; /* Darker green text on hover */
  border-color: #1770e0; /* Darker green border on hover */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Adds a hover shadow */
}

.delete-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%; /* Circular container */
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth hover transition */
}

.delete-icon {
  width: 24px;
  height: 24px;
  color: red; /* Initial red color */
}

.delete-button:hover {
  background-color: #ffe5e5; /* Lightest red background */
}

/* styles.css */

/* Shake Animation */
/* @keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
} */

/* Apply shake animation to the first draggable item */
.shake-animation {
  animation: shake 2s infinite;
  animation-delay: 5s; /* Start after 5 seconds */
}

/* Style for the draggable items */
.draggable-item {
  padding: 8px;
  background: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
  cursor: grab;
  width: calc(20% - 8px); /* 20% width for 5 items per row */
}

/* Style for the droppable container items */
.droppable-item {
  padding: 8px;
  background: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: grab;
  width: calc(20% - 8px); /* 20% width for 5 items per row */
}


/* Responsive Design */
@media (max-width: 600px) {
  .container {
    padding: 1rem;
  }

  .email-item {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .email-item button {
    align-self: flex-end;
  }
  .campaign-detail a {
    color: #007bff;
    text-decoration: none;
    word-break: break-word; /* Ensures words break when needed */
    white-space: normal; /* Allows wrapping */
    overflow-wrap: anywhere;
  }

  .campaign-detail {
    padding: 10px; /* Adjust padding for mobile */
  }
  .campaign-detail {
    gap: 10px;
  }
  .campaign-report-wrapper {
    height: auto; /* Allow height to adjust naturally */
    padding: 10px; /* Add some padding for smaller screens */
  }

  .campaign-report-container {
    min-width: 100%; /* Full width on smaller screens */
    padding: 15px; /* Reduce padding for better spacing */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Soften shadow on small screens */
  }

  .campaign-review-container {
    min-width: 100%; /* Full width on smaller screens */
    padding: 15px; /* Reduce padding for better spacing */
  }
  .campaign-review-detail {
    gap: 10px;
  }
  .import-recipient-wrapper {
    width: 100%;
  }
  .import-card-container {
    width: 100%; /* Make cards full-width on smaller screens */
    margin: 10px; /* Reduce margin for smaller screens */
  }
  .card-content h3 {
    font-size: 12px;
    color: #333;
    margin: 10px 0;
  }

  .card-content p {
    font-size: 12px;
    color: #666;
  }
  .recipient-description {
    font-size: 14px;
  }
  .recipient-title {
    font-size: 20px;
    font-weight: bold;
  }
  .card-image {
    width: 100px; /* Adjust the image size */
    height: 100px;
    object-fit: contain;
  }

  .import-csv-main-container {
    display: flex;
    justify-content: space-between;
    /* padding: 15px; */
    gap: 10px;
  }
  .upload-box {
    width: 100%;
  }
  .csv-template-download {
    width: 50%;
  }
  .container {
    /* max-width: 800px; */
    width: 100%;
    /* margin: 2rem auto; */
    padding: 1rem;
    background: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  .right-container {
    display: none;
  }
  .edit-name {
    font-size: 1rem;
  }
  .edit-name-title {
    font-size: 1.8rem;
    /* font-weight: bold; */
    /* color: #333; */
    /* margin-bottom: 1rem; */
    /* padding: 0 1rem; */
    font-family: "poppins";
  }
  .prev-email-template {
    width: 100px; /* Reduce the width for smaller screens */
    font-size: 12px; /* Adjust font size for readability */
    padding: 10px; /* Add padding for better touch targets */
  }
  .campaign-analysis-grid-container {
    grid-template-columns: repeat(2, 1fr); /* Show 2 cards per row */
    gap: 10px; /* Adjust gap for smaller screens */
    padding: 10px; /* Reduce padding for mobile */
  }
  .campaign-analysis-time-text {
    font-size: 20px;
    font-weight: bold;
    color: gray;
    margin: 0;
    margin-bottom: 5px;
  }
  .performance-container {
    flex-direction: column; /* Stack items vertically */
    align-items: flex-start;
  }

  .performance-container .left-section {
    flex: 1 1 auto; /* Take full width */
    margin-bottom: 16px; /* Add spacing below */
  }

  .performance-container .right-section {
    flex-direction: row; /* Dropdowns in a single row */
    justify-content: space-between;
    width: 100%; /* Take full width */
  }

  .performance-container .right-section .dropdown-box {
    width: calc(50% - 8px); /* Split width for two dropdowns */
  }
}
