.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  /* margin: auto; */
}

.performance-card {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.card-title {
  font-weight: bold;
  margin-bottom: 5px;
}

.date-range {
  color: gray;
  font-size: 14px;
}

.highlight {
  background-color: orange;
  padding: 2px 5px;
  border-radius: 5px;
  color: white;
}

/* Custom Grid for Stats */
.stats-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
  margin-top: 15px;
}

.stat-box {
  text-align: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.stat-label {
  font-size: 14px;
  color: gray;
}

.stat-value {
  font-weight: bold;
}

.stat-change {
  font-size: 12px;
}

.red {
  color: red;
}

.green {
  color: green;
}

.click-rate {
  margin: 10px 0;
}

.bold-text {
  font-weight: bold;
}

.metric-label {
  margin-bottom: 10px;
}

.time-filters {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.filter-button {
  padding: 5px 15px;
  border: 1px solid lightgray;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
}

.filter-button.active {
  background-color: #1976d2;
  color: white;
}

.message-stats {
  font-size: 14px;
  color: gray;
}


/* .container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 800px;
  margin: auto;
} */

.delivery-card {
  padding: 20px;
  border-radius: 10px;
  
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.card-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.email-tab {
  color: #1976d2;
  font-weight: bold;
  border-bottom: 2px solid #1976d2;
  display: inline-block;
  padding-bottom: 5px;
}

.total-deliveries {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.total-deliveries h3 {
  font-size: 2rem;
}

.increase-rate {
  color: green;
  font-weight: bold;
}

.comparison-text {
  color: gray;
  font-size: 14px;
}

.chart-container {
  margin: 20px 0;
}

.metrics-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.metric-label {
  font-size: 14px;
  color: gray;
}

.bold-text {
  font-weight: bold;
}

.time-filters {
  display: flex;
  gap: 10px;
}

.filter-button {
  padding: 5px 15px;
  border: 1px solid lightgray;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
}

.filter-button.active {
  background-color: #1976d2;
  color: white;
}

.delivery-details {
  margin-top: 20px;
  border-top: 1px solid lightgray;
  padding-top: 10px;
}

.detail-item {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  font-size: 14px;
}

